"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuidanceTooltip = void 0;
const tooltip_1 = require("./tooltip");
const state_1 = require("../enums/state");
const position_1 = require("../enums/position");
class GuidanceTooltip extends tooltip_1.Tooltip {
    constructor(options) {
        super(options);
    }
    createTooltipElement() {
        this.tooltip.classList.add('Tooltip');
        this.tooltip.classList.add('Guidance');
        if (this.position === position_1.Position.BOTTOM) {
            this.tooltip.classList.add('Bottom');
        }
        this.tooltip.style.display = "none";
        this.tooltip.innerHTML = `
			<div class="TooltipInner">
				${typeof this.content === 'string' ? this.content : this.content.outerHTML}
				<button class="TooltipClose">
					<i class="TooltipCloseIcon lnr-cross"></i>
				</button>
			</div>
			<div class="TooltipArrow"></div>
		`;
    }
    addEventListeners() {
        if (this.element === null) {
            return;
        }
        const closeButton = this.element.querySelector('.TooltipClose');
        const embeddedLinks = this.element.querySelectorAll('a');
        if (!this.disableClick) {
            this.element.addEventListener('click', () => {
                this.state === state_1.TooltipState.CLOSED ? this.open() : this.close();
            });
        }
        if (closeButton !== null) {
            this.element.querySelector('.TooltipClose').addEventListener('click', (e) => {
                e.stopPropagation();
                this.close();
                // Fire onClose callback when there is one
                if (this.onClose) {
                    this.onClose();
                }
            });
        }
        if (embeddedLinks.length > 0) {
            Array.from(embeddedLinks).forEach((link) => {
                link.addEventListener('click', (e) => {
                    e.stopPropagation();
                    this.close();
                });
            });
        }
    }
}
exports.GuidanceTooltip = GuidanceTooltip;
