"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContextualTooltip = void 0;
const tooltip_1 = require("./tooltip");
const position_1 = require("../enums/position");
class ContextualTooltip extends tooltip_1.Tooltip {
    constructor(options) {
        super(options);
    }
    createTooltipElement() {
        this.tooltip.classList.add('Tooltip');
        if (this.position === position_1.Position.BOTTOM) {
            this.tooltip.classList.add('Bottom');
        }
        this.tooltip.style.display = "none";
        this.tooltip.innerHTML = `
			<div class="TooltipInner">
				${typeof this.content === 'string' ? this.content : this.content.outerHTML}
			</div>
			<div class="TooltipArrow"></div>
		`;
    }
    addEventListeners() {
        if (this.element === null) {
            return;
        }
        this.element.addEventListener('mouseenter', () => {
            this.closeAllTooltips();
            window.requestAnimationFrame(() => this.open());
        });
        this.element.addEventListener('mouseleave', () => {
            this.close();
        });
        document.addEventListener('Tooltip/CloseAllTooltips', () => {
            this.close();
        });
    }
    closeAllTooltips() {
        document.dispatchEvent(new Event('Tooltip/CloseAllTooltips'));
    }
}
exports.ContextualTooltip = ContextualTooltip;
