"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip = void 0;
const position_1 = require("../enums/position");
const state_1 = require("../enums/state");
class Tooltip {
    constructor(options) {
        this.tooltip = document.createElement('div');
        this.state = state_1.TooltipState.CLOSED;
        // The amount of extra padding that the tooltip has for creating extra slack in the hover interaction
        this.outerPadding = 10;
        this.element = options.element;
        this.content = options.content;
        this.position = options.position || position_1.Position.TOP;
        this.disableClick = options.disableClick || false;
        this.onClose = options.onClose || null;
        this.init();
    }
    init() {
        if (this.element === null) {
            console.error("Tooltip: No element was provided!");
            return;
        }
        this.element.style.position = "relative";
        this.createTooltipElement();
        this.element.appendChild(this.tooltip);
        this.addEventListeners();
    }
    open() {
        this.tooltip.style.display = 'block';
        this.state = state_1.TooltipState.OPEN;
        window.requestAnimationFrame(this.checkBounds.bind(this));
    }
    close() {
        this.tooltip.style.display = 'none';
        this.state = state_1.TooltipState.CLOSED;
    }
    checkBounds() {
        this.tooltip.classList.remove('Left', 'Right');
        let bounds = this.tooltip.getBoundingClientRect();
        if (bounds.y < 0 || this.position === position_1.Position.BOTTOM) {
            this.tooltip.classList.add('Bottom');
            //Refresh bounds object since some properties will have changed
            bounds = this.tooltip.getBoundingClientRect();
        }
        if ((bounds.y + bounds.height + this.outerPadding) > window.innerHeight) {
            this.tooltip.classList.remove('Bottom');
        }
        if (window.innerWidth < bounds.width) {
            return;
        }
        const tooltipArrow = this.tooltip.querySelector('.TooltipArrow');
        if (!tooltipArrow) {
            return;
        }
        if (bounds.x < 0) {
            // Re-position the tooltip so it doesn't fall outside of the viewport
            this.tooltip.style.left = `calc(50% + ${Math.abs(bounds.x)}px)`;
            // Re-position the arrow the same amount in the opposite direction so it stays centered
            tooltipArrow.style.left = `calc(50% - ${Math.abs(bounds.x)}px)`;
        }
        if ((bounds.x + bounds.width) > document.documentElement.clientWidth) {
            // Re-position the tooltip so it doesn't fall outside of the viewport
            this.tooltip.style.left = `calc(50% - ${Math.abs((bounds.x + bounds.width) - document.documentElement.clientWidth)}px)`;
            // Re-position the arrow the same amount in the opposite direction so it stays centered
            tooltipArrow.style.left = `calc(50% + ${Math.abs((bounds.x + bounds.width) - document.documentElement.clientWidth)}px)`;
        }
    }
}
exports.Tooltip = Tooltip;
